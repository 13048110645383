@import "../../../assets/styles/shift-reporter/shiftui";
@import '../../../assets/styles/buttons';
@import '../../../assets/styles/variables';


.userListSection {
    width: 100%;
    background: white;
    border-radius: 10px;

    .header {
        display: flex;
        justify-items: flex-end;
        align-items: center;
        padding: $base-spacing*2 0;
        border-bottom: 3px solid $background-color1;

        h3{
        margin-right: auto;
        }
        
        select {
        margin-left: 10px;
        }
    
        .userSummary {
            display: flex;

            p {
                padding: 10px 20px;
                font-weight: 500;
                max-width: 200px;
                border: 2px solid;
                border-radius: 5px;
                color: $primary-color;
                transition: background 0.3s ease;
                
                &:nth-of-type(even) {
                    background: $background-color2;
                }
            }
        }

        h3{
        flex-grow: 1;
        margin-right: auto;
        }
    }

    .columnTitles{
        padding: $base-spacing;
    }

    .userList {
        width: 100%;
        overflow: hidden;
        transition: all 0.3s ease;

    }

    .userItem, .columnTitles {
        display:grid;
        grid-template-columns: repeat(7, 1fr);
        justify-items: start;
        align-items: center;
    }
    
    .userItem {
        justify-items: start;
        align-items: center;
        padding: $base-spacing/2;
        border-top: 1px solid $background-color1;
        position: relative;
        color: $dark-text-mid;

        &:hover {
        background-color: $background-color2;
        }

        &.onshift{
            color: $onshift-color;
        }

        &.unavailable{
            color: $grey;
        }
    }
}

.onshift{
    color: $onshift-color;
}

.unavailable{
    color: $grey;
}
