@import '../../assets/styles/buttons'; 
@import "../../assets/styles/forms";
@import "../../assets/styles/variables";
@import "../../assets/styles/icons";

.pageContainer {
    width: 100%;
    justify-items: center;
    display: flex;
}

.mainContent {
    width: 100%;
    padding: $base-spacing*2 $base-spacing*2 0 $base-spacing*2;
}

.newsTicker{
    position: relative;
    list-style: none;
    width: 100%;
    background: white;
    padding: 0 $base-spacing*2 $base-spacing*2 $base-spacing*2;
    border-radius: 10px;
    margin-bottom: $base-spacing*2;
}

.dataTable {
    grid-column: 2 / 3;
    width: 100%;
    background: white;
    padding: 0 $base-spacing*2 $base-spacing*2 $base-spacing*2;
    border-radius: 10px;
    margin-bottom: $base-spacing*2;

}

.selectTeam {
    width: 100%;

    select {
        width: 100%;
    }
}

.btn {
    width: 140px;
    height: 140px;
    border-radius: 5px;
    position: relative;
    color: white;
    font-size: 20px;
}

.notification {
    display: none;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    background-color: red;

    p {
        color: white;
    }
}

.showNotification {
    display: flex;
} 



