@import "./variables";

.pageContainer {
  width: 100%;
  justify-items: center;
  display: flex;
}

.mainContent {
  width: 100%;
  padding: $base-spacing*2 $base-spacing*2 0 $base-spacing*2;
}

.dataTable {
  grid-column: 2 / 3;
  width: 100%;
  background: white;
  padding: 0 $base-spacing*2 $base-spacing*2 $base-spacing*2;
  border-radius: 10px;
  margin-bottom: $base-spacing*2;
}