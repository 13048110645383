@import "../../../../../assets/styles/shift-reporter/shiftui";
@import '../../../../../assets/styles/buttons';
@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/ant_table';

@media (max-width: 1000px) {
  .countArea{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.countArea{
  padding: 20px;
  .countValue {
    margin-left: 10px;
    font-weight: bolder;
  }
}

.headerDivider{
  margin: 0 0 20px 0;
}

.vehicleInfo{
  padding: 0 20px;
}

.issueList{
  padding: 20px;
  overflow: auto;
  height: 260px;
}

.issueItem{
  .issueTitle{
    font-weight: bolder;
  }
  .issueMessage{
    font-size: 12px;
  }
  .issueDate{
    font-size: 12px;
    color: #666666;
  }
}