@import "./../../assets/styles/variables";
@import "./../../assets/styles/forms";
@import "./../../assets/styles/buttons";
@import "./../../assets/styles/variables";

  .repairFormContainer {
    min-width: 800px;
  }

  .header {
    font-size: 22px;
    padding: 20px;
    text-align: center;
  }

  .maintenanceReport {
    grid-area: Aside;
    width: 100%;
    margin: auto;
    display: grid;
    padding: 2em 0;
    grid-template-columns: 1fr 1fr;
    gap: $base-spacing*2;
    grid-template-areas:
        ". ."
        ". ."
        ". ."
        ". ."
        ". ."
        ". ."
        "select select"
        "textarea textarea"
        "cancel submit";

    input{}

    input, select, .fullwidth {
      width: 100%;
    }

    select{
      max-width: unset;
      grid-area: select;
    }

    label{}

    textarea {
      width: 100%;
      grid-area: textarea;
    }

    button {

      margin: auto;
      border-radius: 4px;


      &[type="button"] {
        grid-area: cancel;
      }

      &[type="submit"] {
        grid-area: submit;
      }

    }

    input, textarea, select{
      border: 2px solid $form-border-color;
      padding: 10px;
      margin: auto;
      border-radius: 3px;
    }

  }

