@import '../../../../assets/styles/buttons'; 
@import '../../../../assets/styles/forms'; 

.userForm {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: $base-spacing*2;
  background-color: white;
  padding: $base-spacing * 2;


  p {
    color: red;
    font-style: italic;
    font-size: 0.8em;
    margin: 0;
    grid-column: 2/3;
  }

  button {
    width: 100%;
  }
}