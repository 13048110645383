@import "./../../../assets/styles/variables";
@import "./../../../assets/styles/buttons";
@import "./../../../assets/styles/forms";

.jobsListSection {
  width: 100%;
  background: white;
  padding: 0 $base-spacing*2 $base-spacing*2 $base-spacing*2;
  border-radius: 10px;

  header {
    display: flex;
    justify-items: flex-end;
    align-items: center;
    padding: $base-spacing*2 0;
    border-bottom: 3px solid $background-color1;

    h3{
      margin-right: auto;
    }
    select {
      margin-left: 10px;
    }
  }

  .columnTitles{
    padding: $base-spacing;
  }

  .jobsList {
    width: 100%;
  }

  .jobItem, .columnTitles {
    display:grid;
    grid-template-columns: 10% 1fr 70px 80px 2fr 5% 110px;
    justify-items: start;
    align-items: center;
  }
  
  .jobItem {
    margin-bottom: $base-spacing;
    padding: 0 $base-spacing;

    &:hover {
      background-color: $background-color2;
    }

    button {
      height: fit-content;
      width: 100%;
    }
  }
}