@import "../../../assets/styles/buttons";

.errorListTable {
  width: 100%;
  background: white;
  border-radius: 10px;

  header {
    display: flex;
    justify-items: flex-end;
    align-items: center;
    padding: $base-spacing*2 0;
    border-bottom: 3px solid $background-color1;

    h3{
      margin-right: auto;
    }
    button, select {
      background-color: $primary-color;
      color: $dark-text-mid;
      &:hover {
        background-color: $background-color3;
      }
    }
  }

  .columnTitles{
    padding: $base-spacing $base-spacing/2;
  }

  .errorList {
    width: 100%;
  }

  .errorItem, .columnTitles {
    display:grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr;
    justify-items: start;
    align-items: center;
  }
  
  .errorItem {
    padding: $base-spacing/2;
    border-top: 1px solid $background-color1;
    position: relative;
    color: $dark-text-mid;

    &:hover {
      background-color: $background-color2;
    }
  }

  .msgItem{
    border-top: 1px solid $background-color1;
  }

  .navigation {
    display: flex;
    justify-content: center;
    padding: $base-spacing;
      
    button {
        height: fit-content;
        margin: $base-spacing;
    }

    button:disabled {
      cursor: not-allowed;
      background-color: $grey;
    }
  }

  .declaration {
    display: flex;
    padding: $base-spacing;

    input {
      margin-right: $base-spacing;
    }

    label {
      margin-left: 5px;
    }
  }
}