@import-normalize;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.shiftDuration{
  display: flex;
  flex-direction: row;
 
} 
.dashboard{
  padding-left: 1rem;
}
.checklist{
  margin-right: 1rem;
}
@media (max-width: 576px){
  .shiftDuration{
    display: flex;
    flex-direction: column !important;
  } 
  .shift-hr{
display: none;
  }
  .team-hr{
    padding-right: 2px;
    padding-bottom: 0;
    padding-top: 0px;
  }
  .dashboard{
    padding-left: 0rem;
    overflow: hidden;

  }
  .checklist{
    margin-left: 1rem;
    margin-right: 0;
  }
}
.shift-hr{
  border-left: 1px solid;
  position: static;
  width: 1px;
  height: 125px;
  left: 403px;
  top: 0px;
  background: rgba(0, 0, 0, 0.12);
  margin: 0px 25px
}

@media (max-width: 1200px){
  .shiftDuration{
    display: flex;
    flex-direction: column !important;
  } 
  .shift-hr{
display: none;
  }
  .dashboard{
    display: flex;
    align-items: center;
    padding: 15px;
    padding-left: 0px;
  }
  .maindashboard{
    padding-top: 0;
  }
  .listbtn{
    margin-top: 0px;
  }

}

