// Put your GENERIC variables in here

$body-bg : #ebedef;
//$body-bg : #696a6c;
$link-color : #fff;
$secondary-color: rgb(80, 99, 255);
$tertiary-color:  rgb(80, 99, 255);
$card-text : 0.7em;
$odometer-dark: #212333;
$odometer-light: #3c4b64;
$odometer-shadow: rgba(0, 0, 0, 0.8);
$odometer-text-shadow: #000;
$odometer-outer-border:rgba(255,255,255,.15);
