@import '../../assets/styles/buttons';
@import "../../assets/styles/variables";
@import "../../assets/styles/generic";

.pageContainer {
    width: 100%;
    justify-items: center;
    display: flex;
    background-color: $background-color1;
}

.sectionTitle {
    font-size: 28px;
    font-weight: bold;
}

