// Put your GENERIC variables in here

// COLOR VARIABLES //
$background: #5C636A;

//table background color accents
$background-color1: rgb(240, 240, 250);
$background-color2: rgb(230, 237, 255);

$background-color3: rgba(44, 44, 44, 0.6);
$background-color4: rgba(255, 255, 255, 0.8);

//colour variables
$primary-color: rgb(80, 99, 255);
$secondary-color: rgb(80, 99, 255);
$tertiary-color:  rgb(80, 99, 255);

$headings-color : #696a6c ;

$success-color: rgb(16, 212, 13);
$success-color-lighten: rgb(4, 253, 0);
$warning-color: #ffc107;
$info-color: #17a2b8;
$info-color-alt: $warning-color;
$danger-color: #dc3545;
$danger-color-lighten: #ff0019;

$grey-lighten-more: #eeeeee;
$grey-lighten: #e0e0e0;
$grey: #9e9e9e;
$grey-darken: #616161;
$form-border-color: lightgray;

//text colour variables
$dark-text-high: rgb(33,41,69);
$dark-text-mid: rgb(142, 149, 168);
$dark-text-low:rgb(185, 188, 197);
$light-text-high: rgb(250,250,250);
$light-text-mid: rgb(240,240,240);
$light-text-low: rgb(200,200,200);

//fault classes colors
$class-a-color: #D40D0D;
$class-b-color: #000000;
$class-c-color: #ffc107;
$class-handover-color: #b1670d;
$load-approved-color: #001cee;

//spacings
$base-spacing: 8px;
$onshift-color : #008000;


