@import "../../../../../assets/styles/shift-reporter/shiftui";
@import '../../../../../assets/styles/buttons';
@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/ant_table';


.headerDivider{
  margin: 0 0 20px 0;
}

.vehicleInfo{
  padding: 0 20px;
}