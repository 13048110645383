@import './assets/styles/_forms';
@import './assets/styles/_variables';

/** TODO: why did mergetool pull this in?
@import "./assets/styles/shift-reporter/shiftui";
@import "./assets/styles/buttons";
@import "./assets/styles/variables";
 **/
@import './components/PreviewCamera/PreviewCamera.scss';

.active,
.error,
.warning,
.success {
  opacity: 1;
}

.active {
  &, * {
    color: inherit;
  }
}

.inert {
  &, * {
    color: inherit;
  }
}

.error {
  &, * {
    color: red;
  }
}

.warning {
  &, * {
    color: orange;
  }
}

.success {
  color: green;
}

dl.property-list {
  list-style: none;
  text-align: left;

  dt,
  dd {
    float: left;
  }

  dt {
    text-align: right;
    clear: left;
    width: 70px;
    margin-right: 3px;
  }

  dt::after {
    content: ':';
  }

  dd {
    text-align: left;
    clear: right;
  }
}

/** TODO: time changed to string format, is this required? **/
div.time {
  .hours, .minutes, .seconds, .separator {
    font-size: 1em;
  }
  .milliseconds {
    font-size: 0.8em;
  }
}

/** TODO: move this into its own file **/
.table-responsive.schedules-table {
  table.table {
    thead, tbody {
      tr {
        th {
        }
        td {
        }
        th.column-actions, td.column-actions {
          width: 120px;
        }
        th.column-actions {
          vertical-align: middle;
          text-align: center;
          padding: 0;
        }
        td.column-actions {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: flex-start;
        }

        td.cell-for-day {
          min-height: 35px;
          height: 35px;
          padding-left: 0;
          padding-right: 0;
          div.dot {
            width: 15px;
            height: 15px;
            border-width: 1px;
            border-style: solid;
            border-color: #ccc;
            border-radius: 15px;
            margin: 0 auto;
          }
        }

        td.on-schedules, td.off-schedules, div.droppable-wrapper, ul.droppable {
          min-height: 72px;
        }

        td.on-schedules, td.off-schedules {
          padding-top: 5px !important;
          padding-bottom: 5px !important;

          div.droppable-wrapper {
            padding: 0;
            margin: 0 !important;

            ul.droppable {
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              align-items: flex-start;
              align-content: space-around;
              flex-wrap: wrap;
              list-style: none;
              margin-bottom: 0;

              border-width: 1px;
              border-style: dashed;
              border-color: #888888;
              border-radius: 10px;

              li.draggable {
              }
            }
          }
        }
      }
    }

    thead {
      tr {
        th {
        }

        th.sorter-left {
          > div {
            margin-left: 5px;
          }

          > svg {
            left: 0;
          }
        }

        th.no-padding {
          padding: 0 !important;
        }
      }
    }

    tbody {
      tr {
        td {
          border: none;
        }
      }
    }
  }
}

.table-form {
  table.table {
    border-width: 1px;
    border-style: solid;
    border-color: #d8dbe0;
    border-radius: 0.25rem;
    margin-bottom: 0;
    thead {
      tr {
        th {
          vertical-align: middle;
          overflow: hidden;
          width: 30px;
          text-align: center;
          background: #d8dbe0;
          color: #768192;
          border: none;
        }
      }
    }
    tbody {
      tr {
        td.cell-for-day {
          padding: 0px auto;
          text-align: center;
          vertical-align: middle;

          label {
            display: inline-block;
            width: 20px;
            height: 20px;
            border-width: 1px;
            border-style: solid;
            border-color: #39f;
            border-radius: 20px;
            margin: 0px auto;
          }
          input {
            display: none;
          }
        }
      }
    }
  }
  table.is-invalid {
    border-color: #e55353;
    tbody {
      tr {
        td.cell-for-day {
          label {
            border-color: #e55353;
          }
        }
      }
    }
  }
}

