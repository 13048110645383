
.userInfo {
  margin: 10px 10px 20px 10px;
  .userId {
    font-size: 12px;
    color: rgba(37, 44, 59, 0.7);
  }
}

.endShit{
  color: red;
}