.loaderContainer {
  background-color: rgba(0,0,0,.5);

  z-index: 2000!important;

  .loader {
    z-index: 2000!important;
  }

}
