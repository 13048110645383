@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import "./variables";


h1 {
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  color: $headings-color;
  text-transform: uppercase;
  padding: $base-spacing*2;


}

h2 {
  font-size: 1rem;
  font-weight: 500;
  color: $headings-color;
  text-align: Left;
  padding: $base-spacing*2;

}

h3 {
  font-size: 1rem;
  font-weight: 500;
  color: $headings-color;
  // text-transform: uppercase;
}

h4 {
  font-size: 0.8rem;
  font-weight: 500;
  color: $headings-color;
}

p{
  font-size: 0.8rem;
  color: $dark-text-mid;
}

.info{
  color: $info-color-alt;
}

.success{
  color: $success-color;
}

.error,
.danger{
  color: $danger-color;
}

.icon-blue{
  color: $dark-text-high
}

.icon-black{
  color: #000;
}

.icon-gray{
  color: $grey;
}


.link{
  cursor: pointer;
}
