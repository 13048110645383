@import '../../../../assets/styles/buttons'; 
@import '../../../../assets/styles/forms'; 
.partForm {
  margin-top: 1em;
  display: grid;
  align-items: center;
  background-color: white;
  gap:$base-spacing * 2;
  grid-template-columns: 1fr 1fr;
  
  padding: $base-spacing * 2;


  p {
    color: red;
    font-style: italic;
    font-size: 0.8em;
    margin: 0;
    grid-column: 2/3;
  }
  button {
    width: 100%;
  }
}