@import '../../../assets/styles/buttons';

.asideHeader {
    font-size: 50px;
    padding: 50px;
    text-align: center;
}


.maintenancReport {
    grid-area: Aside;
    width: 80%;
    margin: auto;
    display: grid;
    gap: 2em;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:    ". ."
                            ". ."
                            "text text"
                            "submit submit"
    ;
    
    input {
        width: 65%;
        margin: auto;
        border: 2px solid rgb(51, 51, 51);
        border-radius: 15px;
    }

    textarea {
        border: 2px solid rgb(51, 51, 51);
        margin: auto;
        width: 85%;
        height: 100%;
        grid-area: text;
        border-radius: 15px;
    }
    button {
        margin: auto;
        border-radius: 15px;

        
        &[type="submit"] {
            grid-area: submit;
        }
    }
}
