@import "../../../assets/styles/shift-reporter/shiftui";
@import "../../../assets/styles/variables";
@import "../../../assets/styles/fonts";

//li {
//    padding: $base-spacing;
//}
// Don't write global styles

.header {
    display: flex;
    justify-items: flex-end;
    align-items: center;
    padding: $base-spacing*2 0;
    border-bottom: 3px solid $background-color1;

    h3{
        margin-right: auto;
    }
    select {
        margin-left: 10px;
    }
}

.columnTitles{
    padding: $base-spacing;
    display: grid;
    grid-template-columns: (1fr 1fr 4fr 50px);
}


