@import "../../../assets/styles/shift-reporter/shiftui";
@import '../../../assets/styles/buttons';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/ant_table';

@media (max-width: 1000px) {
  .countArea{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.countArea{
  padding: 20px;
  .countValue {
    margin-left: 10px;
    font-weight: bolder;
  }
}