@import "./shiftvariables";
@import "~@coreui/coreui/scss/coreui";

h2, h3, h1{
  color: #696a6c;
}

.faux-link{
  cursor: pointer;
}

.card-text{
  font-size: 0.8rem;
}

.tableContainer{

  thead {
    tr {
      th {
        border-top: 0;
      }
    }
  }

}

.paginationContainer{

  li{
    a{
      color: $dark;

      &:hover{
        color: $white;
      }

    }
  }

}

.c-icon {
  &.vehicleCheckListComplete {
    &.info {
      path{
        &.checkIconColor{
          color: $info;
        }
      }
    }

    &.success {
      path{
        &.checkIconColor{
          color: $success;
        }
      }
    }

    &.danger {
      path{
        &.checkIconColor{
          color: $danger;
        }
      }
    }

    &.warning {
      path{
        &.checkIconColor{
          color: $warning;
        }
      }
    }

  }
}

.odometer-digit{
  background-color: $odometer-dark;
  background-image: linear-gradient(to bottom, $odometer-dark 0%, $odometer-dark 20%, $odometer-light 50%, $odometer-dark 90%, $odometer-dark 100%);
  box-shadow: inset 0 0 0.3em $odometer-shadow;
  border: 4px solid $odometer-outer-border;
  text-shadow: -1px -1px 2px $odometer-text-shadow;
  margin-left: 2px;
  margin-right: 2px;
}
