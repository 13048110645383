@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/buttons";

.newsItemModal{

  display: grid;
  justify-items: start;
  align-items: center;
  min-width: 500px;

  .time{
    font-style: italic;
  }

  .title{
    font-weight: bold;
    padding: 1em 0;
  }

  .additionalInfoContainer{
    width: 100%;
  }

  .issuesContainer{
    margin: 1em 0 0 0;
  }

  .issueItem, .columnTitles{
    display: grid;
    grid-template-columns: 3fr 3fr 4fr;
    padding: 8px 0;
    border-top: 1px solid $background-color1;
  }

  .issueItem{
    justify-items: start;
    align-items: center;
    padding: $base-spacing/2;
    border-top: 1px solid $background-color1;
    position: relative;
    color: $dark-text-mid;

    &:hover {
      background-color: $background-color2;
    }

  }

  .issuesFooter{
    padding: 2em 0 0 0;
    grid-column-gap: 0;
    grid-template-columns: 0fr 1fr;
    justify-items: end;
    align-items: center;
    display: none;

    &.footerVisible{
      display: grid;
    }

    .noIssuesRecorded{
      padding: 1em 0;
    }

  }

  .footerMessage{
      padding: 2em 0 0 0;
      text-align: center;
  }


}