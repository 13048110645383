.loginContainerBG {

  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../assets/images/background-image.jpg");
  background-position: center;

  @media ( max-width: 575.98px ) {
    align-items: center;
  }

}

.bgleft{}