@import '../../assets/styles/buttons';
@import "../../assets/styles/variables";
@import "../../assets/styles/shift-reporter/shiftvariables";
.pageContainer {
  width: 100%;
  justify-items: center;
  display: flex;
}

.mainContent {
  width: 100%;
  padding: $base-spacing*2 $base-spacing*2 0 $base-spacing*2;
}

.heading{
  color: $light-text-high;
  text-transform: uppercase;
}