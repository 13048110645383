@import "./../../assets/styles/variables";
@import "./../../assets/styles/buttons";

.pageContainer {
    width: 100%;
    justify-items: center;
    display: flex;
}

.mainContent {
    width: 100%;
    padding: $base-spacing*2 $base-spacing*2 0 $base-spacing*2;
}


.classA {
    color: $class-a-color;
}

.classB {
    color: $class-b-color;
}

.classC {
    color: $class-c-color;
}

.classError {
    color: black;
}