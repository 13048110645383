@import "../../assets/styles/variables";
@import "../../assets/styles/forms";
@import "../../assets/styles/buttons";


.validation {
    color: red;
    font-size: 1em;
    margin-top: 5px;
    grid-column: 2/3;
}

@media (max-width: 500px) {
      .resmodalcheckbox{
        min-width: 45%;
    margin: 5px 0px 15px 0px;
      }
  }



