@import '../../assets/styles/buttons';

.pageContainer {
    width: 100%;
    justify-items: center;
    display: flex;
}

.mainContent {
    width: 100%;
    padding: $base-spacing*2 $base-spacing*2 $base-spacing*2 0;
}

.cardMed{
    height: 150px;
}