@import "../../assets/styles/variables";
@import "../../assets/styles/forms";
@import "../../assets/styles/buttons";

.validation {
    color: red;
    font-size: 1em;
    margin-top: 5px;
    grid-column: 2/3;
}

.accordionheader {
    font-weight: 500;
    font-size: 0.875rem;
    cursor:pointer;
}

.cardheader {
    padding: 0.50rem 0.83rem;
    margin-bottom: 0;
    border-bottom: 1px solid;
    background-color: #fff;
    border-color: #d8dbe0;
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.cardbody {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0.83rem;
}

@media (max-width: 768px) {
    .modalcheckboxgroup{
      min-width: 45%;
      margin: 5px 0px 15px 0px;
    }
}

