@import './assets/styles/buttons';
@import './assets/styles/forms';
@import './assets/styles/variables';

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

html {
    background: $background-color1;
}

body {
    margin: 0;
    padding: 0;
}

table th {
    border-top: none !important;
    
}

a {
    color: #000 !important;
}
