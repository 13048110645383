@import '../../assets/styles/shift-reporter/shiftui';
@import '../../assets/styles/variables';
@import '../../assets/styles/buttons';

.btnNavLink {
  font-size: 15px !important;
  padding: 14px !important;

  &:hover {
    color: $dark-text-mid !important;
  }
}

.trafficLightSignal {
  display: flex;
  padding: 0 $base-spacing*2;

  .defaultSignal {
    width: 40px;
    height: 40px;
    margin: 5px;
    border: 2px solid #8E95A8;
    border-radius: 50%;
  }

  .redSignal {
    background-color: #ff392e;
  }

  .amberSignal {
    background-color: #FFA500;
  }

  .greenSignal {
    background-color: #2DA488;
  }
}
