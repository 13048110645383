@import "../../../assets/styles/variables";
@import '../../../assets/styles/buttons';
@import '../../../assets/styles/forms'; 
@import '../../../assets/styles/fonts';
@import "../../../assets/styles/generic";
@import "../../Supervisor/NewsTicker/NewsTicker.module";
@import "../../Supervisor/Supervisor.module";


.newsfeedSection{
    width: 100%;
    background: white;
    border-radius: 10px;

}

.outerContainer{
    padding: 16px;
}

.innerContainer{
    width: 100%;
}