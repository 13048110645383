@import "../../assets/styles/shift-reporter/shiftui";
@import "../../assets/styles/buttons";
@import "../../assets/styles/variables";
@import '../../assets/styles/ant_table';

.messageContent{
  max-width: 300px;
}

.mbs100{
    max-width:100px;
    margin:auto;
}
.scale15 {
    transform: scale(1.5) !important;
    margin-left:0px !important;
}
.btReportIncident {
    margin: 0px 10px;
    min-width: 195px;
}
.itable {
    thead {
        tr:nth-child(even) {
            background-color: rgba(0, 0, 21, 0.075);
        }
    }
}
.customtblfiltercont {
    float: right;
    min-width: 560px;
    margin-left: 10px;

}
.customcheckcontainer {
    display: inline-block;
}
.customlabel{
    margin-left:10px;
}
.incidentListSection {
  width: 100%;
  background: white;
  border-radius: 10px;

  .header {
    display: flex;
    justify-items: flex-end;
    align-items: center;
    padding: $base-spacing*2 0;
    border-bottom: 3px solid $background-color1;

    h3{
    margin-right: auto;
    }

    select {
    margin-left: 10px;
    }

    .incidentSummary {
      display: flex;

      p {
        padding: 10px 20px;
        font-weight: 500;
        max-width: 200px;
        border: none;
        border: 2px solid transparent;
        border-radius: 5px;
        color: $primary-color;
        transition: background 0.3s ease;

          &:nth-of-type(even) {
              background: $background-color2;
          }
      }
    }
  }

  .columnTitles {
    padding: $base-spacing;
  }

  .incidentList {
    width: 100%;
    overflow: hidden;
    transition: all 0.3s ease;
  }

  .incidentItem, .columnTitles {
    display:grid;
    grid-template-columns: repeat(9, 1fr);
    justify-items: start;
    align-items: center;
  }

  .incidentItem {
    justify-items: start;
    align-items: center;
    padding: $base-spacing/2;
    border-top: 1px solid $background-color1;
    position: relative;
    color: $dark-text-mid;

    &:hover {
    background-color: $background-color2;
    }

    &.unavailable p {
      color: $danger-color;
    }

  }

}

.no-go{
  color: $danger-color;
}

.go{
  color: inherit;
}

.on-shift{
  color: $onshift-color;
}

tr {
  &.not-available {
    color: $danger-color;
  }
  &.default-state {
    color: $grey
  }
  &.on-shift-available {
    color: $onshift-color;
  }
}
