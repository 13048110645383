:global {
  .ant-table-thead > tr > th{
    font-size: .8rem;
    color: #3c4b64;
    font-weight: bold;
    font-family: Poppins;
    background: #d8dbe0;
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-table-thead th.ant-table-column-sort{
    background: #d8dbe0 !important;
  }
  .ant-table-tbody > tr > td{
    font-size: .8rem;
    color: #3c4b64;
    font-family: Poppins;
  }
  .ant-table-pagination.ant-pagination {
    margin: 16px;
  }

  .ant-card {
    margin: 0 0 20px 0;
  }
}
