@import "./variables";
@import "./fonts";

// Put your GENERIC button styles in here

.btnSM {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.btnLG {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
}

.btnLink{
  height: 50px;
  width: 100%;
  padding: $base-spacing*2;
  border-radius: 0;
  position: relative;
  text-align: left;
  border: 0;
  transition: 0.1s ease-in-out;
  color: #fff;
  cursor: pointer;
  background: none;
  font-weight: 500;

  &:hover {
    color: $dark-text-mid;
  }

}

.btnNav {
  height: 50px;
  width: 100%;
  padding: $base-spacing*2;
  border-radius: 5px;
  position: relative;
  text-align: left;
  border: 0;
  transition: 0.1s ease-in-out;
  color: $dark-text-mid;
  cursor: pointer;
  background: none;
  font-weight: 500;
  
  &:hover {
    color: $dark-text-high;
  }
}

.btnJobClaim {
  padding: $base-spacing/2;
  font-weight: 500;
  max-width: 200px;
  background: $background-color2;
  border: none;
  border-radius: 5px;
  color: $primary-color;
  transition: background 0.3s ease;

  &:hover {
    cursor: pointer;
  }
}

.btnPrimary {
  padding: 10px 20px;
  font-weight: 500;
  max-width: 200px;
  background: $primary-color;
  border: none;
  border-radius: 5px;
  color: white;
  transition: background 0.3s ease;

  &:hover {
    cursor: pointer;
  }
}

.btnSecondary {
  padding: 10px 20px;
  font-weight: 500;
  max-width: 200px;
  background: $background-color2;
  border: none;
  border-radius: 5px;
  color: $primary-color;
  transition: background 0.3s ease;

  &:hover {
    cursor: pointer;
  }
}

.btnSuccess {
  padding: $base-spacing/2;
  border-radius: 5px;
  position: relative;
  margin: $base-spacing/2;
  border: 1px solid $grey;
  transition: 0.15s ease-in-out;
  cursor: pointer;
  
  color: $dark-text-mid;
  background-color: $success-color;
  &:active, &:hover {
    background-color: $success-color-lighten;
  }
}

.btnInfo {
  padding: $base-spacing/2;
  border-radius: 5px;
  position: relative;
  margin: $base-spacing/2;
  border: 1px solid $grey;
  transition: 0.15s ease-in-out;
  cursor: pointer;

  color:$dark-text-mid;
  background-color: $info-color;
  &:active, &:hover {
    background-color: $background-color4;
  }
}

.btnWarning {
  padding: $base-spacing/2;
  border-radius: 5px;
  position: relative;
  margin: $base-spacing/2;
  border: 1px solid $grey;
  transition: 0.15s ease-in-out;
  cursor: pointer;

  color: $dark-text-mid;
  background-color: $warning-color;
  &:active, &:hover {
    background-color: $background-color4;
  }
}

.btnDanger {
  padding: $base-spacing/2;
  border-radius: 5px;
  position: relative;
  margin: $base-spacing/2;
  border: 1px solid $grey;
  transition: 0.15s ease-in-out;
  cursor: pointer;

  color: $dark-text-mid;
  background-color: $danger-color;
  &:active, &:hover {
    background-color: $danger-color-lighten;
  }
}

.btnDisabled {
  padding: $base-spacing/2;
  border-radius: 5px;
  position: relative;
  margin: $base-spacing/2;
  border: 1px solid $grey;
  transition: 0.15s ease-in-out;
  cursor: pointer;

  opacity: 0.65;

  &:active, &:hover {
    background-color:rgb(146, 38, 38);
    color:rgb(255, 255, 255);
  }
}
