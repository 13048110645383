@import "../../../assets/styles/shift-reporter/shiftvariables";
.info{
  background: $body-bg;
  padding: 0 20px 20px 20px;
  .helloLabel{
    font-size: 20px;
    font-weight: bold;
  }
  .countNumber{
    margin: 0 10px;
    font-weight: bold;
  }
}

:global {
  .ant-select {
    width: 200px;
  }
}
