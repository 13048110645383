#preview-camera {
  position: relative;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  align-items: stretch;
  overflow: hidden;
  border-radius: 3px;
  border: 1px solid #cfcfcf;

  .video {
    position: relative;
    z-index: 20;
    width: 100%;
    height: auto;
  }

  .canvas, .flashing, .overlay {
    position: absolute;
  }

  .canvas {
    z-index: 30;
  }

  @keyframes hiding {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 0.95;
    }
    50% {
      opacity: 0.9;
    }
    75% {
      opacity: 0.45;
    }
    100% {
      opacity: 0;
    }
  }

  .canvas.hiding {
    animation: hiding 600ms ease-out;
  }

  .flashing {
    z-index: 40;
    background-color: #ffffff;
    opacity: 0;
  }

  @keyframes flashing {
    from {
      opacity: 0.75;
    }
    to {
      opacity: 0;
    }
  }

  .flashing.active {
    animation: flashing 750ms ease-out;
  }

  .overlay {
    top: 20px;
    left: 20px;
    z-index: 50;
    display: flex;
    justify-content: space-around;
    flex-flow: row nowrap;
    align-items: flex-end;
    border: 1px solid #ffffff;
    box-shadow: 0px 0px 20px 40px rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    padding: 10px;

    button {
      background-color: transparent;
      border-width: 3px;
      border-style: solid;
      border-color: #ffffff;
      border-radius: 30px;
      padding: 2px;

      > div {
        border-width: 5px;
        border-style: solid;
        border-radius: 50px;
        border-color: #ffffff;
        background-color: #ffffff;
        text-align: center;
        width: 35px;
        height: 35px;

        > svg {
          font-size: 1.8em;
        }
      }

      > div:hover {
        opacity: 1;
      }
    }
  }
}
