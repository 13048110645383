@import '../../assets/styles/variables';
@import '../../assets/styles/buttons';

.divBody{
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
  @media screen and (max-width: 1000px)  {
    padding-left: 1em !important;
    padding-right: 1em !important;
    height: 100%;

  }
}

.main {
  flex-basis: auto;
  flex-shrink: 0;
  flex-grow: 1;
  min-width: 0;
  margin-top: 1rem;

  @media screen and (max-width: 1000px) {
    height: 100%;
    
  }
}

.sideNav {
  width: 250px;
  padding: $base-spacing*2;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  justify-items: center;
  align-items: flex-start;
  // background: $grey-lighten;
  transition: 0.3s width ease;
  font-size: 16px;

}



.docked {
  width: 80px !important;
  overflow: hidden;
}

.sideNavHeader {
  width: 100%;
  display: flex;
  justify-content: center;
  background: white;
  border-radius: 10px;
}

.sideNavMenu {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 $base-spacing*2;

}

.accountMenu {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  //background: white;
  //border-radius: 10px;
  overflow: hidden;
  padding: $base-spacing*2;
}

.accountMenu li {
  cursor: pointer;
  padding: 0;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.3s ease-in-out;
  transform-origin: left;
  // overflow-x: hidden;
  color: $dark-text-mid;

  &:hover {
    color: $dark-text-high;
  }
}

.accountMenuLogout,
.accountMenuPassword {
  // transform: translateX(-100%);
}

.accountMenuOpen {
  height: 300px;
}

.faIcon {
  font-size: 1.5rem;
  justify-self: center;
  margin-right: 10px;
}

.header-config {
  height: 60px !important;

  .header-nav {
    height: 60px;
  }
}
